<template>
  <ValidationObserver class="nivea-form" ref="observer" v-slot="{ invalid, passes }" tag="form" @submit.prevent="onSubmit">
    <slot :passes="passes" :set-errors="setErrors" />
  </ValidationObserver>
</template>

<script>
export default {
  name: 'NiveaForm',
  props: {
    submit: {
      type: Function,
      default() {},
    },
    error: {
      type: Function,
      default() {},
    },
    successMessage: String,
    errorMessage: String,
  },
  methods: {
    setErrors(errors) {
      this.$refs.observer.setErrors(errors);
    },
    onError(error) {
      if (error.response) {
        if (error.response.data.errors) {
          this.setErrors(error.response.data.errors);
        }
      }

      // Notify when error
      if (this.errorMessage) {
        this.$noty.error(this.errorMessage);
      }

      if (error.response.data.errors) {
        const errors = Object.values(error.response.data.errors).flat();
        this.$noty.error(errors[0]);
      }

      // Error callback
      this.error(error);
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        const errors = Object.values(this.$refs.observer.errors)
          .filter((bag) => bag.length);

        this.$noty.error(errors[0]);

        return;
      }

      try {
        await this.submit();

        // Notify when success
        if (this.successMessage) {
          this.$noty.success(this.successMessage);
        }
      } catch (error) {
        this.onError(error);
      }
    },
  },
};
</script>

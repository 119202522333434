var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'a' : 'button',{tag:"component",staticClass:"button",class:[
    {
      'button--ghost': _vm.ghost,
      'button--light': _vm.light,
      'button--expanded': _vm.expanded,
      'button--small': _vm.small,
      'button--disabled': _vm.disabled,
      'button--is-icon': _vm.isIcon,
      'button--is-link': _vm.isLink,
    },
    ("button--" + _vm.color) ],attrs:{"href":_vm.href,"type":"button","disabled":_vm.disabled},on:{"click":_vm.onClick}},[(_vm.loading)?_c('div',{staticClass:"button-loader"},[_c('div',{staticClass:"spinner-border"})]):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }
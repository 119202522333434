<template>
  <main class="register-user admin-page bg-cream px-12 ml-auto">
    <div class="container min-h-screen flex flex-col pt-12 pb-20">
      <header class="admin-page-header mb-10">
        <h1 class="text-3xl text-blue">Administradores / Novo cadastro</h1>
      </header>

      <nivea-form :submit="submit" success-message="Administrador convidado com sucesso.">
        <field name="name" label="Nome" rules="required" v-slot="{ label }">
          <v-text-field v-model="form.name" :label="label" hide-details="auto" />
        </field>

        <field name="email" label="E-mail" rules="required|email" v-slot="{ label }">
          <v-text-field type="email" v-model="form.email" autocomplete="username" :label="label" hide-details="auto" />
        </field>

        <div class="bg-salmon bg-opacity-20 my-10 px-6 py-3 rounded-lg">
          <p class="text-blue text-sm">A senha de acesso será criada automaticamente e enviada para o e-mail cadastrado.</p>
        </div>

        <Button type="submit">Cadastrar</Button>
      </nivea-form>
    </div>
  </main>
</template>

<script>
import api from '@/utils/api.js';
import NiveaForm from '@/components/NiveaForm';
import Field from "@/components/fields/Field";
import Button from "@/components/Button";

export default {
  name: "RegisterAdmin",
  components: {
    NiveaForm,
    Field,
    Button,
  },
  data() {
    return {
      form: {
        name: '',
        email: ''
      }
    }
  },
  methods: {
    onInput() {
      this.$emit('input', this.form);
    },

    async submit() {
      await api.post('admins', this.form);
      this.$router.push({ name: 'Admins' });
    }
  }
};
</script>

<style lang="scss">
.register-user {
  .nivea-form {
    width: 480px;
  }

  .field + .field {
    @apply mt-12;
  }
}
</style>

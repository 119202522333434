<template>
  <component
    :is="to ? 'a' : 'button'"
    :href="href"
    class="button"
    :class="[
      {
        'button--ghost': ghost,
        'button--light': light,
        'button--expanded': expanded,
        'button--small': small,
        'button--disabled': disabled,
        'button--is-icon': isIcon,
        'button--is-link': isLink,
      },
      `button--${color}`,
    ]"
    type="button"
    @click="onClick"
    :disabled="disabled"
  >
    <div class="button-loader" v-if="loading">
      <div class="spinner-border"></div>
    </div>
    <slot v-else />
  </component>
</template>

<script>
export default {
  name: "Button",
  props: {
    to: {
      type: Object,
      default: null,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "blue",
    },
    text: {
      type: String,
      default: "blue",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    isLink: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    href() {
      if (!this.to) {
        return null;
      }

      return this.$router.resolve(this.to).href;
    },
  },
  methods: {
    onClick(event) {
      if (this.to) {
        event.preventDefault();
        this.$router.push(this.to);
      }

      this.$emit("click", event);
    },
  },
};
</script>

<style lang="scss">
.button {
  @apply relative inline-flex items-center justify-center py-5 px-10 text-sm leading-tight border-2 rounded-full border border-transparent border-solid transition duration-300;
  min-height: 59px;

  &:focus {
    @apply outline-none;
  }

  &:disabled {
    opacity: 0.6;
  }

  :root & {
    -webkit-appearance: none;
  }
}

.button--is-icon {
  @apply px-0 py-0 flex items-center justify-center;
  width: 3.7rem;
  height: 2rem;
}

.button--salmon {
  @apply border-salmon bg-salmon text-blue;

  &.button--ghost.button--light {
    @apply text-white;
    border-color: #fff;
  }
}

.button--blue {
  @apply border-blue bg-blue text-white;

  &.button--ghost {
    @apply text-blue;
  }

  &.button--light {
    color: #5167CF;
    border-color: #5167CF;
  }
}

.button--expanded {
  @apply flex w-full;
}

.button--small {
  min-height: 28px;
  padding: 10px 20px;
}

.button--ghost {
  @apply bg-transparent;
}

.button--is-link {
  @apply bg-transparent rounded-none text-blue p-0 border-0 mt-4 font-light;
  min-height: 30px;
}

.button--disabled {
  @apply opacity-75 pointer-events-none;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.button-loader {
  @apply absolute inset-0 flex items-center justify-center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <ValidationProvider
    class="field relative"
    tag="div"
    :rules="rules"
    :name="label"
    :vid="name"
    v-slot="{ errors }"
    persist="persist"
    slim
  >
    <div class="field-body">
      <slot :label="label" />
    </div>

    <ul class="field-errors pl-3" v-if="errors.length">
      <li
        class="field-error leading-tight"
        v-for="error in errors"
        :key="error"
      >
        {{ error }}
      </li>
    </ul>
  </ValidationProvider>
</template>

<script>
export default {
  name: "Field",
  props: {
    rules: [String, Object],
    name: String,
    label: String,
    model: {},
    persist: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
.field {
    
  }
.field {
  @apply relative block w-full;

  & + & {
    @apply mt-6;
  }

  select,
  textarea,
  input {
    @apply appearance-none w-full py-3 outline-none;
    @apply transition duration-300 ease-in-out;
    @apply text-blue leading-snug text-lg font-body;

    &::placeholder {
      @apply text-blue transition duration-300 ease-in-out;
    }

    &:focus {
      @apply text-blue;

      &::placeholder {
        @apply text-blue;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 9999s 9999s ease-out;
      -webkit-text-fill-color: theme('colors.blue');
    }
  }

  input {
    @apply bg-transparent;
  }

  textarea {
    resize: none;
  }
}

.v-text-field .v-label {
  transform-origin: left;
}

.field-note {
  @apply text-sm;
}

.field-errors {
  @apply text-sm mt-2 text-red-500 hidden;
}

.field-error {
  @apply lowercase mt-1;
}

.field-prefix {
  @apply absolute left-0 top-0 text-white flex items-center justify-center bg-white bg-opacity-25 h-full px-4 rounded-l-full transition duration-300 ease-in-out;
}
</style>
